import { type FC, Fragment, useState, useEffect, } from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { useSummaryViewedRate } from '@web-solutions/core/hooks/use-summary-viewed-rate';

import { getPersonalizedData } from 'src/store/profile/selectors';

import { EVENT_ACTION } from 'src/constants/events';

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { PersonalizationPopupContextProvider } from 'src/hooks/use-personalization-popup-context';

import { RootState } from 'src/store/types';

import { PersonalizationPopUp } from '../personalization';

import { SECTIONS } from './sections';

import { useFixedLanding } from './hooks/use-fixed-landing';

import classes from './style.module.scss';

const usersPercentToActivateScrollMetric = process.env.REACT_APP_USERS_PERCENT_TO_ACTIVATE_SCROLL_METRIC;

const Landing: FC = () => {
  const { landingStack } = useRemoteConfig();

  const personalizedData = useSelector(getPersonalizedData);
  const isAppLoaded = useSelector((state: RootState) => state.app.loaded)

  const [isPersonalizationPopupVisible, setIsPersonalizationPopupVisible] = useState(false);

  useFixedLanding(isPersonalizationPopupVisible);
  useSummaryViewedRate({ usersPercentToActivateScrollMetric, isSummaryReady: isAppLoaded });

  useEffect(() => {
    Analytics.trackEvent('landing', EVENT_ACTION.OPEN, { isPersonalized: !!personalizedData });
  }, [personalizedData]);

  const handleCloseClick = () => {
    setIsPersonalizationPopupVisible(false);
  }

  const handleSubmit = () => {
    setIsPersonalizationPopupVisible(false);
  }

  const renderSections = () => landingStack.map((item) => {
    const Component = SECTIONS[item];

    if (!Component) {
      return null;
    }

    return (
      <Fragment key={item}>
        <Component />
      </Fragment>
    );
  });


  return (
    <PersonalizationPopupContextProvider value={{ onOpen: () => setIsPersonalizationPopupVisible(true) }}>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {renderSections()}
          <PersonalizationPopUp
            visible={isPersonalizationPopupVisible}
            onSubmit={handleSubmit}
            onClose={handleCloseClick}
          />
        </div>
      </div>
    </PersonalizationPopupContextProvider>
  );
};

export default Landing;
