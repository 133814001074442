import { type FC, Suspense } from 'react';
import { Route, } from 'react-router-dom';

import { Loader } from '@web-solutions/core/components/loader';

import { QUIZ, QUIZ_NAMES } from 'src/constants/routes';
import { QUIZ_SCREENS } from 'src/constants/screens';

import { QuizWrapper } from 'src/hocs/quiz-wrapper';
import { QuizTransition } from 'src/hocs/quiz-transition';
import { QuizStepWrapper } from 'src/hocs/quiz-step-wrapper';

import { QuizStep } from 'src/screens/quiz/quiz-step';
import { ProgressBar } from 'src/screens/quiz/progress-bar';

export const QuizRouter: FC = () => (
  <QuizWrapper>
    <ProgressBar />
    <QuizTransition >
      {Object.values(QUIZ_NAMES).map((r, i) => {
        const Screen = QUIZ_SCREENS[r] || QuizStep
        const route = QUIZ[r];

        return (
          <Route key={i} path={route} element={
            <QuizStepWrapper >
              <Suspense fallback={<Loader />}>
                <Screen currentPage={r} />
              </Suspense>
            </QuizStepWrapper>
          }>
          </Route>
        );
      })}
    </QuizTransition>
  </QuizWrapper>
)
