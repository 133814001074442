import { PersonalizedData } from "src/store/profile";

import type { BirthLocationType, BirthDateType, BirthTimeType, Validation } from "./types";

export const isValidNumber = (value: string): boolean => /^\d+$/.test(value);

export const checkFormValidity = (validation: Validation) => Object.values(validation).every(value => value);

export const checkIsValidBirthLocation = (data: BirthLocationType): boolean => {
  const hasAllFields = ['label', 'lat', 'lon'].every(field => field in data);

  if (!hasAllFields) {
    return false;
  }

  const { label, lat, lon } = data;

  return [label, lat, lon].every(value => value.length > 0);
};

export const checkIsValidBirthDate = (data: BirthDateType): boolean => {
  const { day, month, year } = data;

  return [day, month, year].every(value => isValidNumber(value) && value.length > 0);
};

export const validateFormField = ({ name, value }: { name: keyof PersonalizedData, value: string | BirthLocationType | BirthTimeType | BirthDateType }) => {
  let isValid = true;

  switch (name) {
    case 'birth_date':
      isValid = checkIsValidBirthDate(value as BirthDateType);
      break;
    case 'location':
      isValid = checkIsValidBirthLocation(value as BirthLocationType);
      break;
  }

  return isValid
}

export const isBirthDateKey = (key: string): key is keyof BirthDateType => {
  return key === 'day' || key === 'month' || key === 'year';
};