import lazy, { PreloadableComponent } from 'react-lazy-with-preload';

import { SummarySection } from 'src/types/remote-config';

export const SECTIONS: {
  [key in SummarySection]: PreloadableComponent<React.FC>
} = {
  [SummarySection.WELCOME_GALERY]: lazy(() => import('./welcome-galery')),
  [SummarySection.BIRTH_CHART]: lazy(() => import('./birth-chart')),
  [SummarySection.BOOK_INFO]: lazy(() => import('./book-info')),
  [SummarySection.BOOK_SPECIFICATIONS]: lazy(() => import('./book-specifications')),
  [SummarySection.CONTACT_US]: lazy(() => import('./contact-us')),
  [SummarySection.DELIVERY_STEPS]: lazy(() => import('./delivery-steps')),
  [SummarySection.FAQ]: lazy(() => import('./faq')),
  [SummarySection.GREAT_INSIGHTS]: lazy(() => import('./great-insights')),
  [SummarySection.SHARE_JOY]: lazy(() => import('./share-joy')),
  [SummarySection.SURPRISE]: lazy(() => import('./surprise')),
  [SummarySection.WELCOME]: lazy(() => import('./welcome')),
  [SummarySection.WHAT_INSIDE]: lazy(() => import('./what-inside')),
  [SummarySection.HEADER]: lazy(() => import('./header')),
  [SummarySection.PRODUCTS]: lazy(() => import('./products')),
  // personalization
  [SummarySection.WHY_CHOOSE_US]: lazy(() => import('./why-choose-us')),
  [SummarySection.PLANETS_AFFECT]: lazy(() => import('./planets-affect')),
  [SummarySection.COSMIC_GUIDANCE]: lazy(() => import('./cosmic-guidance')),
  [SummarySection.BOOK_SWIPER]: lazy(() => import('./book-swiper')),
  [SummarySection.READERS_SAY]: lazy(() => import('./readers-say')),
};
