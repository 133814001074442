import { t } from '@web-solutions/module-localization';

import { InputWrapper } from 'src/screens/components/input-wrapper';
import { EmailInput } from 'src/screens/components/email-input';

import { Validation, ShippingCommonProps } from '../../types';

import classes from '../../style.module.scss';
import 'react-phone-input-2/lib/style.css'

const tKey = 'shipping_information';

interface Props {
  commonProps: ShippingCommonProps,
  validation: Validation,
}

export const ShortForm: React.FC<Props> = ({
  commonProps,
  validation,
}) => {
  const { data } = commonProps;

  return (
    <>
      <p className={classes.title}>{t(`${tKey}.contact_title`)}</p>
      <div className={classes.contact}>
        <p className={classes.group_title}>{t(`${tKey}.book_recipient`)}</p>
        <div className={classes.short_fields_container}>
          <div className={classes.field_wrap_short}>
            <InputWrapper
              name="name"
              type='text'
              value={data.name}
              validation={validation}
              {...commonProps}
            />
          </div>
          <div className={classes.field_wrap_short}>
            <InputWrapper
              name="last_name"
              type='text'
              value={data.last_name}
              validation={validation}
              {...commonProps}
            />
          </div>
        </div>
      </div>
      <p className={classes.group_title}>{t(`${tKey}.details_title`)}</p>
      <div className={classes.field_wrap_long}>
        <EmailInput
          name="email"
          type='email'
          value={data.email}
          validation={validation}
          {...commonProps}
        />
      </div>
    </>
  )
}