import type { FC } from 'react'
import { useSelector } from 'react-redux'

import { t } from '@web-solutions/module-localization'

import Analytics from '@web-solutions/module-analytics';

import { Text, Title } from '@web-solutions/core/ui-elements'

import { EVENT_ACTION } from 'src/constants/events';

import { getShippingInformation } from 'src/store/profile/selectors'

import { selectActiveProduct } from "src/store/cart/selectors";

import { ProductAndCustomerInfo } from 'src/components/product-and-customer-info'
import { Footer } from 'src/components/order/footer'

import { FreeProducts } from 'src/components/free-products';

import { ProductType } from 'src/constants/products';

import { baseTKey } from './constants'

import { SuccessIcon } from './icons'

import { NavHeader, OrderStatus, OrderDetails } from './components'

import classes from './style.module.scss'

const targetLink = process.env.REACT_APP_WEB_APP_URL as string;

export const OrderSuccess: FC = () => {
  const { email } = useSelector(getShippingInformation);
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;

  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const handleClick = () => {
    Analytics.trackEvent('go_to_app', EVENT_ACTION.CLICK);
    window.location.href = targetLink;
  }

  return (
    <div className={classes.orderSuccessWrapper}>
      <NavHeader />
      <main className={classes.mainWrapper}>
        <div className={classes.confirmationWrapper}>
          <div className={classes.titleWrapper}>
            <SuccessIcon className={classes.icon} />
            <Title level='h2' className={classes.title}>
              {t(`${baseTKey}.main.title`)}
            </Title>
          </div>
          <Text className={classes.subtitle}>
            {t(`${baseTKey}.main.subtitle`)}
          </Text>
          <span className={classes.email}>
            {email}
          </span>
        </div>
        <section className={classes.section}>
          <ProductAndCustomerInfo withoutFreeProducts />
          {isHardCoverBook && <OrderStatus />}
          <FreeProducts type='success' onClick={handleClick}/>
          <div className={classes.devider} />
          <OrderDetails />
        </section>
        <Footer />
      </main>
    </div>
  )
}