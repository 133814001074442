import _mapValues from 'lodash/mapValues';

export const QUIZ_NAMES = {
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  QUIZ_MAILING: 'QUIZ_MAILING',
  QUIZ_PALM_READING: 'QUIZ_PALM_READING',

  QUIZ_BIRTHDAY: 'QUIZ_BIRTHDAY',
  QUIZ_BIRTHTIME: 'QUIZ_BIRTHTIME',
  QUIZ_BIRTHPLACE: 'QUIZ_BIRTHPLACE',

  QUIZ_RELATIONSHIP_STATUS: 'QUIZ_RELATIONSHIP_STATUS',
  QUIZ_FUTURE_GOALS: 'QUIZ_FUTURE_GOALS',
  QUIZ_COLORS: 'QUIZ_COLORS',
  QUIZ_ELEMENT: 'QUIZ_ELEMENT',

  QUIZ_SIGNS_MAGIC: 'QUIZ_SIGNS_MAGIC',

  QUIZ_TRANSITION_HAND: 'QUIZ_TRANSITION_HAND',
  QUIZ_TRANSITION_MAP: 'QUIZ_TRANSITION_MAP',
} as const;

export const QUIZ: { [key in QuizNameKeys]: string } = _mapValues(QUIZ_NAMES, (v) => '/' + v + '/*');

export const PUBLIC_ROUTES = {
  MAIN: '/main',
}

export const PROTECTED_ROUTES = {
  SUCCESS: '/success',
  SHIPPING: '/shipping',
  CART: '/cart',
}

export const ROUTES = {
  ...PROTECTED_ROUTES,
  ...PUBLIC_ROUTES,
  ...QUIZ,
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_WELCOME,
  QUIZ_NAMES.QUIZ_SIGNS_MAGIC,
  QUIZ_NAMES.QUIZ_PALM_READING,
]

type QuizNameGeneralKeys = keyof typeof QUIZ_NAMES;
export type QuizNamesGeneralValues = typeof QUIZ_NAMES[QuizNameGeneralKeys];

export type QuizNameKeys = QuizNameGeneralKeys;
export type QuizNamesValues = Array<QuizNamesGeneralValues>;