import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Select } from '@web-solutions/core/ui-elements';
import { InputPhone } from '@web-solutions/core/containers/phone-input';
import { PlaceAutocomplete } from '@web-solutions/core/containers/place-picker/components';

import type { PlaceData } from '@web-solutions/core/containers/place-picker/types';

import { InputWrapper } from 'src/screens/components/input-wrapper';
import { EmailInput } from 'src/screens/components/email-input';
import type { ShippingInformation } from 'src/store/profile';

import { getFormattedCity, getSearchQuery } from '../../utils';

import { Validation, FocusedFields, Countries, States, ShippingCommonProps } from '../../types';

import classes from '../../style.module.scss';
import 'react-phone-input-2/lib/style.css'

const tKey = 'shipping_information';

interface Props {
  commonProps: ShippingCommonProps,
  validation: Validation,
  focusedFields: FocusedFields,
  countries: Countries,
  isNeedState: boolean,
  states: States,
  debouncedCityValue: string,
  setData: (value: React.SetStateAction<ShippingInformation>) => void,
  setValidation: (value: React.SetStateAction<Validation>) => void,
  onCitySelect: (value: PlaceData) => void
}

export const FullForm: React.FC<Props> = ({
  commonProps,
  validation,
  focusedFields,
  countries,
  isNeedState,
  states,
  debouncedCityValue,
  setData,
  setValidation,
  onCitySelect,
}) => {
  const { data } = commonProps;

  return (
    <>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      <p className={classes.group_title}>{t(`${tKey}.book_recipient`)}</p>
      <p className={classes.recipient_note}>{t(`${tKey}.book_recipient_note`)}</p>
      <div className={classes.short_fields_container}>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="name"
            type='text'
            value={data.name}
            validation={validation}
            {...commonProps}
          />
        </div>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="last_name"
            type='text'
            value={data.last_name}
            validation={validation}
            {...commonProps}
          />
        </div>
      </div>
      <p className={classes.group_title}>{t(`${tKey}.contact_title`)}</p>
      <div className={classNames(classes.field_wrap_long, classes.email)}>
        <EmailInput
          name="email"
          type='email'
          value={data.email}
          validation={validation}
          {...commonProps}
        />
      </div>
      <p className={classes.group_title}>{t(`${tKey}.details_title`)}</p>
      <div className={classes.field_wrap_long}>
        {(focusedFields.country || data.country.value) && <span className={classes.inside_label}>{t(`${tKey}.form.labels.country`)}</span>}
        <InputWrapper name="country" validation={validation} tKey={tKey}>
          <Select
            className={classNames(classes.field, { [classes.error_border]: !validation.country })}
            iconClassName={classes.arrow}
            name="country"
            options={countries}
            defaultValue={data.country.label}
            loading={!countries}
            {...commonProps}
          />
        </InputWrapper>
        <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.country || data.country.value })}>
          {t(`${tKey}.form.placeholders.country`)}
        </span>
      </div>
      {isNeedState &&
        <div className={classes.field_wrap_long}>
          {(focusedFields.state || data.state.value) && <label htmlFor="state" className={classes.inside_label}>{t(`${tKey}.form.labels.state`)}</label>}
          <InputWrapper name="state" validation={validation} tKey={tKey}>
            <Select
              className={classNames(classes.field, { [classes.error_border]: !validation.state })}
              iconClassName={classes.arrow}
              name="state"
              options={states}
              defaultValue={data.state.label}
              loading={!states}
              {...commonProps}
            />
            <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.state || data.state.value })}>
              {t(`${tKey}.form.placeholders.state`)}
            </span>
          </InputWrapper>
        </div>
      }
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="city"
          type='text'
          value={data.city}
          validation={validation}
          {...commonProps}
        />
        <PlaceAutocomplete
          show={focusedFields['city'] && Boolean(data.country.value)}
          value={{ label: data.city.trim() }}
          searchValue={getSearchQuery({ city: debouncedCityValue, country: data.country.value, state: data.state.value })}
          formatter={getFormattedCity}
          onSelect={onCitySelect}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="street"
          type='text'
          value={data.street}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="apartment"
          type='text'
          value={data.apartment}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="zip_code"
          type='text'
          value={data.zip_code}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        {(focusedFields.phone || data.phone) &&
          <label htmlFor="phone"
            className={classes.inside_label}>
            {t(`${tKey}.form.labels.phone`)}
          </label>
        }
        <InputWrapper name="phone" validation={validation} tKey={tKey}>
          <InputPhone
            phoneNumber={data.phone}
            autoCountry={data.phone ? false : true}
            containerClassName={classes.container}
            inputClassName={classNames(classes.input, {
              [classes.error_border]: !validation.phone,
              [classes.gap]: data.phone
            })}
            buttonClassName={classes.flag_button}
            dropdownClassName={classes.dropdown}
            placeholder={t(`${tKey}.form.placeholders.phone`)}
            name='phone'
            setPhoneNumber={(value) => setData({ ...data, phone: value })}
            onValidation={(value) => setValidation({ ...validation, phone: value })}
            {...commonProps}
          />
        </InputWrapper>
      </div>
      <p className={classes.footnote}>{t(`${tKey}.footnote`)}</p>
    </>
  )
}