


import BROKEN_HURT_1X from './broken_hurt.webp'
import BROKEN_HURT_2X from './broken_hurt@2x.webp'
import BROKEN_HURT_3X from './broken_hurt@3x.webp'

import CIRCLE_STAR_1X from './circle_star.webp'
import CIRCLE_STAR_2X from './circle_star@2x.webp'
import CIRCLE_STAR_3X from './circle_star@3x.webp'

import HURTS_1X from './hurts.webp'
import HURTS_2X from './hurts@2x.webp'
import HURTS_3X from './hurts@3x.webp'

import RING_1X from './ring.webp'
import RING_2X from './ring@2x.webp'
import RING_3X from './ring@3x.webp'

export const OTHER_IMAGES = {
  BROKEN_HURT: {
    src: BROKEN_HURT_1X,
    srcSet: `${BROKEN_HURT_1X}, ${BROKEN_HURT_2X} 2x, ${BROKEN_HURT_3X} 3x`
  },
  CIRCLE_STAR: {
    src: CIRCLE_STAR_1X,
    srcSet: `${CIRCLE_STAR_1X}, ${CIRCLE_STAR_2X} 2x, ${CIRCLE_STAR_3X} 3x`
  },
  HURTS: {
    src: HURTS_1X,
    srcSet: `${HURTS_1X}, ${HURTS_2X} 2x, ${HURTS_3X} 3x`
  },
  RING: {
    src: RING_1X,
    srcSet: `${RING_1X}, ${RING_2X} 2x, ${RING_3X} 3x`
  }
}