import React from 'react';
import classNames from 'classnames';

import { Theme } from 'core/constants/theme';

import classes from './index.module.scss';

interface Props {
  onClick: (id: string) => void;
  selected: boolean;
  title: string;
  id: string;
  theme: Theme;
}

const NumberAnswer: React.FC<Props> = ({
  onClick,
  selected,
  title,
  id,
  theme
}) => {
  const itemClassName = classNames(classes.number__item,
    { [classes.active]: selected },
    { [classes.dark]: theme === Theme.dark },
  );

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };

  return (
    <li
      onClick={handleClick}
      className={itemClassName}
    >
      <div>{title}</div>
    </li>
  );
};

export default NumberAnswer;
