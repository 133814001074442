import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { MemoryRouter, } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { RootState } from 'src/store/types';

import { QuizRouter } from './quiz-router'
import { LandingRouter } from './landing-router'

export const Routing: FC = () => {
  const startRoute = useSelector((state: RootState) => state.routing.startRoute) as string

  return (
    <MemoryRouter initialEntries={[startRoute]}>
      {startRoute !== ROUTES.MAIN && (
        <QuizRouter />
      )}
      <LandingRouter />
    </MemoryRouter>
  )
}
