import { QUIZ_SCREENS } from 'src/constants/screens';

export const getScreens = () => {
  const screens = { ...QUIZ_SCREENS };

  return screens;
};

export const preloadPage = (page) => {
  const screens = getScreens();
  const Screen = screens[page];

  if (Screen?.preload) {
    Screen.preload();
  }
};