import AIR_1X from './air@1x.webp'
import AIR_2X from './air@2x.webp'
import AIR_3X from './air@3x.webp'

import EARTH_1X from './earth@1x.webp'
import EARTH_2X from './earth@2x.webp'
import EARTH_3X from './earth@3x.webp'

import FIRE_1X from './fire@1x.webp'
import FIRE_2X from './fire@2x.webp'
import FIRE_3X from './fire@3x.webp'

import WATER_1X from './water@1x.webp'
import WATER_2X from './water@2x.webp'
import WATER_3X from './water@3x.webp'

export const ELEMENTS_IMAGES = {
  AIR: {
    src: AIR_1X,
    srcSet: `${AIR_1X}, ${AIR_2X} 2x, ${AIR_3X} 3x`
  },
  EARTH: {
    src: EARTH_1X,
    srcSet: `${EARTH_1X}, ${EARTH_2X} 2x, ${EARTH_3X} 3x`
  },
  FIRE: {
    src: FIRE_1X,
    srcSet: `${FIRE_1X}, ${FIRE_2X} 2x, ${FIRE_3X} 3x`
  },
  WATER: {
    src: WATER_1X,
    srcSet: `${WATER_1X}, ${WATER_2X} 2x, ${WATER_3X} 3x`
  },
}