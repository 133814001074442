
import { useSelector } from 'react-redux';

import { quizProgressSelector, type QuizProgress } from '@web-solutions/core/store/quiz/selectors';

export const useQuizProgress = (): QuizProgress | null => {
  const stepProgress = useSelector(quizProgressSelector);

  return stepProgress;
};
