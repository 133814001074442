import React from 'react';
import classNames from 'classnames';

import { ImageSource } from 'core/interfaces/images';

import { Theme } from 'core/constants/theme';

import classes from './style.module.scss';

interface Props {
  onClick: (id: string) => void;
  title: string;
  id: string;
  selected: boolean;
  theme: Theme;
  image?: ImageSource;
}

const OvalAnswer: React.FC<Props> = ({
  onClick,
  title,
  id,
  selected,
  image = null,
  theme,
}) => {

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };

  const classOval = classNames(
    classes.oval,
    { [classes.dark]: theme === Theme.dark },
    { [classes.active]: selected },
  );

  return (
    <li className={classOval}>
      <button
        onClick={handleClick}
        id={`${title}_container`}
        className={classes.button}
        type="button"
      >
        {image && <img src={image.src} srcSet={image.srcSet} alt="answer" />}
        <div className={classes.description}>
          <h4 id={`${title}_title`} className={classes.title}>
            {title}
          </h4>
        </div>
      </button>
    </li>
  );
};

export default OvalAnswer;
