import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { getPersonalizedData } from 'src/store/profile/selectors';

export const ProtectedRoute = () => {
  const personalizedData = useSelector(getPersonalizedData);

  if (!personalizedData) {
    return <Navigate replace to={{
      pathname: ROUTES.MAIN,
      search: window.location.search,
    }} />
  }

  return <Outlet />
}