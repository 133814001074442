import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Image, Title } from '@web-solutions/core/ui-elements';

import { ProductType } from 'src/constants/products';

import Button from '../button';

import { IMAGES } from './images';

import { DisplayType, FreeProductType } from './types';

import classes from './style.module.scss';

const tKey = 'products.free_products';

interface Props {
  type: DisplayType;
  productType?: ProductType;
  onClick?: () => void;
}

export const FreeProducts: React.FC<Props> = ({ type, productType, onClick }) => {
  const isCard = type === 'card';
  const isSuccess = type === 'success';
  const isHardCoverBook = productType === ProductType.BIRTH_CHART_HARDCOVER;
  const products = isHardCoverBook || isSuccess ? ['EBOOK', 'APP'] : ['APP'];

  return (
    <div className={classes.free_products}>
      {isSuccess &&
        <Title level='h2' className={classes.main_title}>
          {t(`${tKey}.title`)}
        </Title>
      }
      {products.map((product, index) => (
        <div key={product} className={classNames(classes.block, {
          [classes.card]: isCard,
          [classes.success]: type === 'success'
        })}>
          <div className={classes.content}>
            <Image
              className={classes.image}
              img={IMAGES[product as FreeProductType][type]}
              alt={product}
            />
            <div className={classes.texts}>
              <p className={classes.title}>
                <T k={`${tKey}.${product}.title`} />
              </p>
              <p className={classes.description}>{t(`${tKey}.${product}.${type}_text`)}</p>
            </div>
          </div>

          {(onClick && index === products.length - 1) && (
            <Button className={classes.button} onClick={onClick}>
              {t(`${tKey}.btn_text`)}
            </Button>
          )}
        </div>
      ))}
    </div>
  )
};
