import React, { type BaseSyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t, T } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useGetDebouncedValue } from '@web-solutions/core/hooks/use-get-debounced-value';

import type { PlaceData } from '@web-solutions/core/containers/place-picker/types';

import { processEmail, setShippingInformation } from 'src/store/profile/actions';

import { CheckboxWithLabel } from 'src/screens/components/checkbox-with-label';

import { prepareFormDataToSave } from 'src/utils';

import { useRemoteConfig } from 'src/hooks/use-remote-config'

import { selectActiveProduct } from "src/store/cart/selectors";

import { ProductType } from 'src/constants/products';

import { CompleteButton } from '../complete_button';

import { useForm } from '../../hooks/use-form';

import { checkFormValidity } from './utils';

import { FullForm } from './components/full-form';
import { ShortForm } from './components/short-form';

import classes from './style.module.scss';
import 'react-phone-input-2/lib/style.css'

const tKey = 'shipping_information';

interface Props {
  onChangeStatus: () => void;
}

export const ShippingInformation: React.FC<Props> = ({ onChangeStatus }) => {
  const dispatch = useDispatch();
  const {
    validation,
    countries,
    states,
    data,
    isNeedState,
    handleChange,
    handleCheckChange,
    setValidation,
    setData,
  } = useForm();

  const { policyLinks } = useRemoteConfig();

  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;

  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const [focusedFields, setFocusedFields] = useState({
    country: false,
    state: false,
    phone: false,
    city: false,
  });

  const debouncedCityValue = useGetDebouncedValue(data.city.trim());
  console.log(data, 'data')
  const isFormValid = checkFormValidity(validation, data, isNeedState, isHardCoverBook);

  const handleFocus = (e: BaseSyntheticEvent) => {
    const { name } = e.target;

    setFocusedFields((prev) => ({ ...prev, [name]: true }))
  }

  const handleBlur = (e: BaseSyntheticEvent) => {
    const { name } = e.target;

    setTimeout(() => setFocusedFields((prev) => ({ ...prev, [name]: false })), 200);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Analytics.trackEvent('shipping_information', EVENT_ACTION.SUBMIT, { data });

    const { email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks } = prepareFormDataToSave(data);

    dispatch(setShippingInformation({ email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks }));
    dispatch(processEmail(email));

    onChangeStatus();
  }

  const handleCitySelect = (v: PlaceData) => {
    //@ts-ignore
    handleChange({ target: { name: 'city', value: v?.label ?? data.city } });
  }

  const commonProps = {
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    inside: true,
    data,
    tKey,
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {isHardCoverBook ?
        <FullForm
          commonProps={commonProps}
          validation={validation}
          focusedFields={focusedFields}
          countries={countries}
          isNeedState={isNeedState}
          states={states}
          debouncedCityValue={debouncedCityValue}
          setData={setData}
          setValidation={setValidation}
          onCitySelect={handleCitySelect}
        />
        :
        <ShortForm
          commonProps={commonProps}
          validation={validation}
        />
      }

      <div className={classes.agreementsWrapper}>
        <CheckboxWithLabel
          name='personal'
          text={t(`${tKey}.personal_data_agreement`)}
          isChecked={data.checks.personal}
          onChange={handleCheckChange}
        />
        <CheckboxWithLabel
          name='terms'
          text={
            <T
              k={`${tKey}.terms_agreement`}
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              components={{ serviceTermsLink: <a href={policyLinks.termsOfUse} className={classes.link} /> }}
            />
          }
          isChecked={data.checks.terms}
          onChange={handleCheckChange}
        />
      </div>
      <CompleteButton title={t(`${tKey}.form.button_text`)} disabled={!isFormValid} />
    </form >
  )
}