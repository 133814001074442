import { DisplayType, FreeProductType } from '../types';

import EBOOK_CARD_1X from './ebook.webp';
import EBOOK_CARD_2X from './ebook@2x.webp';
import EBOOK_CARD_3X from './ebook@3x.webp';

import EBOOK_SUCCESS_1X from './ebook_big.webp';
import EBOOK_SUCCESS_2X from './ebook_big@2x.webp';
import EBOOK_SUCCESS_3X from './ebook_big@3x.webp';

import APP_CARD_1X from './app.webp';
import APP_CARD_2X from './app@2x.webp';
import APP_CARD_3X from './app@3x.webp';

import APP_SUCCESS_1X from './app_big.webp';
import APP_SUCCESS_2X from './app_big@2x.webp';
import APP_SUCCESS_3X from './app_big@3x.webp';

interface Image {
  src: string;
  srcSet: string;
}

export const IMAGES: Record<FreeProductType, Record<DisplayType, Image>> = {
  EBOOK: {
    card: {
      src: EBOOK_CARD_1X,
      srcSet: `${EBOOK_CARD_1X} 1x, ${EBOOK_CARD_2X} 2x, ${EBOOK_CARD_3X} 3x`,
    },
    success: {
      src: EBOOK_SUCCESS_1X,
      srcSet: `${EBOOK_SUCCESS_1X} 1x, ${EBOOK_SUCCESS_2X} 2x, ${EBOOK_SUCCESS_3X} 3x`,
    }
  },
  APP: {
    card: {
      src: APP_CARD_1X,
      srcSet: `${APP_CARD_1X} 1x, ${APP_CARD_2X} 2x, ${APP_CARD_3X} 3x`,
    },
    success: {
      src: APP_SUCCESS_1X,
      srcSet: `${APP_SUCCESS_1X} 1x, ${APP_SUCCESS_2X} 2x, ${APP_SUCCESS_3X} 3x`,
    }
  }
}