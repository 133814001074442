import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar as CoreProgressBar } from '@web-solutions/progress-bar';

import { SCREENS_WITHOUT_PROGRESSBAR } from 'src/constants/routes';

import { selectFlowBlocks } from 'src/store/routing/selectors';

import { useQuizNavigation } from 'src/hooks/use-quiz-navigation';
import { useQuizProgress } from 'src/hooks/use-quiz-progress';

import classes from './style.module.scss';

export const ProgressBar: FC = () => {
  const flow = useSelector(selectFlowBlocks);

  const { prevScreen } = useQuizNavigation();
  const data = useQuizProgress();

  if (!data) {
    return null;
  }

  return (
    <CoreProgressBar
      onlyLogo={false}
      className={classes.progressBarWrapper}
      flow={flow}
      hidden={!data.isIncludesPage}
      logo={
        <span className={classes.logo}>Astroline</span>
      }
      currentStep={data.currentStepIndex}
      currentPage={data.currentPage}
      totalSteps={data.total}
      pagesWithoutProgress={SCREENS_WITHOUT_PROGRESSBAR}
      onBackClick={prevScreen}
    />
  );
};
