import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { Title } from '@web-solutions/core/ui-elements'

import { ShopProduct } from 'src/store/cart/types'

import { ProductType } from 'src/constants/products';

import { selectActiveProduct } from "src/store/cart/selectors";

import classes from './style.module.scss'

interface Props {
  product: ShopProduct
}

export const ProductPrices: FC<Props> = ({ product }) => {
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const { activePrice, notActivePrice } = product;

  return (
    <div className={classes.productPricesWrapper}>
      <Title className={classes.activePrice}>
        {activePrice}
      </Title>
      {isHardCoverBook &&
        <div className={classes.notActivePriceWrapper}>
          <p className={classes.notActivePrice}>
            {notActivePrice}
          </p>
        </div>
      }
    </div>
  )
}
