import React from 'react';
import classNames from 'classnames';

import { Theme } from 'core/constants/theme';

import classes from './style.module.scss';

interface Props {
  onClick: (id: string) => void;
  title: string;
  icon: React.ReactNode;
  id: string;
  selected: boolean;
  idDisabled: boolean;
  theme: Theme;
}

const MiniRectangleAnswer: React.FC<Props> = ({ onClick, title, icon = null, id, selected, idDisabled, theme }) => {

  const classMiniRectangle = classNames(
    classes['mini-rectangle'],
    { [classes.dark]: theme === Theme.dark },
    { [classes.active]: selected }
  );

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };

  return (
    <li>
      <button
        onClick={handleClick}
        id={`${title}_container`}
        className={classMiniRectangle}
        type="button"
        disabled={idDisabled}
      >
        <div className={classes.button}>
          {icon}
          <div className={classNames(classes['description'], { [classes.withIcon]: Boolean(icon) })}>
            <h3 id={`${title}_title`} className={classes.title}>
              {title}
            </h3>
          </div>
        </div>
      </button>
    </li>
  );
};

export default MiniRectangleAnswer;
