import type { FC } from "react";
import lazy, { PreloadableComponent } from 'react-lazy-with-preload';

import BookLanding from 'src/screens/book-landing';
import { OrderSuccess } from 'src/screens/order/order-success';
import { Shipping } from "src/screens/shipping";
import Cart from "src/screens/cart";

import { QUIZ_NAMES, QuizNameKeys, ROUTES } from "./routes";

export const SCREENS = {
  [ROUTES.MAIN]: <BookLanding />,
  [ROUTES.SHIPPING]: <Shipping />,
  [ROUTES.SUCCESS]: <OrderSuccess />,
  [ROUTES.CART]: <Cart />
};

export const QUIZ_SCREENS: Partial<Record<QuizNameKeys, PreloadableComponent<FC<any>>>> = {
  [QUIZ_NAMES.QUIZ_WELCOME]: lazy(() => import('src/screens/quiz/gender/common')),

  [QUIZ_NAMES.QUIZ_BIRTHDAY]: lazy(() => import('src/screens/quiz/birthdate/birthdate-wheel')),
  [QUIZ_NAMES.QUIZ_BIRTHTIME]: lazy(() => import('src/screens/quiz/birthtime/birthtime-wheel')),
  [QUIZ_NAMES.QUIZ_BIRTHPLACE]: lazy(() => import('src/screens/quiz/birthplace')),
  [QUIZ_NAMES.QUIZ_PALM_READING]: lazy(() => import('src/screens/quiz/palm-reading')),

  [QUIZ_NAMES.QUIZ_TRANSITION_HAND]: lazy(() => import('src/screens/quiz/transitions/hand')),
  [QUIZ_NAMES.QUIZ_TRANSITION_MAP]: lazy(() => import('src/screens/quiz/transitions/map')),

  [QUIZ_NAMES.QUIZ_SIGNS_MAGIC]: lazy(() => import('src/screens/magic')),

  [QUIZ_NAMES.QUIZ_MAILING]: lazy(() => import('src/screens/quiz/mailing')),
}