
import classes from './style.module.scss';

interface Props {
  text: string;
}

const NoteBlock: React.FC<Props> = ({ text }) => (
  <div className={classes.note}>
    <span className={classes.icon}>💡</span>
    <p className={classes.text}>{text}</p>
  </div>
)

export default NoteBlock;
