import _map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t, T } from '@web-solutions/module-localization';

import { Button, Text } from 'core/ui-elements';

import { EVENT_ACTION } from 'core/constants/general';

import { ImagesMap } from 'core/interfaces/images';

import { Data } from 'core/interfaces/quiz';
import { Theme, } from 'core/constants/theme';
import { QuizStepKind, } from 'core/containers/questions/types';

import { DATA_COMPONENTS } from '../constants';

import classes from './style.module.scss';

const tKey = 'core.questions';

export interface MultiplyAnswersProps {
  analyticsCategory: string;
  icons?: {
    [key: string]: string
  };
  images?: ImagesMap;
  kind?: QuizStepKind;
  onClick: (value: string[]) => void;
  data: Data;
  theme?: Theme;
  limited?: number;
  showSelectionsHint?: boolean;
  classNameSquare?: string;
  withCheckmark?: boolean;
  answersListClassName?: string;
  iconRight?: boolean;
}

const MultiplyAnswers: React.FC<MultiplyAnswersProps> = ({
  analyticsCategory,
  icons,
  onClick,
  kind = QuizStepKind.Rectangle,
  limited = null,
  data,
  theme = Theme.light,
  images,
  answersListClassName,
  classNameSquare,
  withCheckmark,
  showSelectionsHint,
  iconRight = true,
}) => {
  const { answers, title, subtitle } = data;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const AnswerList = DATA_COMPONENTS[kind];
  const dataTypeClassName = `answers-list_${kind}`;

  useEffect(() => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.OPEN);
  }, [analyticsCategory]);

  const handleBtnClick = () => {
    selectedIds.forEach((id) => Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: id }));
    onClick(selectedIds);
  };

  const toggleItem = (id: string) => {
    if (id === 'select_all') {
      setSelectedIds(selectedIds.includes(id) ? [] : Object.keys(answers));
      return;
    }

    if (id === 'none') {
      setSelectedIds([]);
      return;
    }

    if (!selectedIds.includes(id) && (!limited || selectedIds.length < limited)) {
      setSelectedIds((prevIds) => [...prevIds, id]);

      if (selectedIds.includes('none')) {
        setSelectedIds([id]);
      }

    } else {
      const filteredItems = selectedIds.filter((item) => item !== id);
      setSelectedIds(filteredItems);
    }
  };

  const classQuestion = classNames(classes.question, {
    [classes.dark]: theme === Theme.dark,
  })

  return (
    <div className={classQuestion}>
      <div className={classes.wrap}>
        <h1 className={classes.title}>
          <T k={title} />
          {
            (showSelectionsHint && !subtitle) && <div className={classes.selections_hint}>{t(`${tKey}.subtitle`)}</div>
          }
        </h1>
        {subtitle && <Text className={classes.subtitle}>{subtitle}</Text>}
        {limited && (
          <div className={classes['selected-items']}>
            {t(`${tKey}.selected_title`)}:
            <h4 className={classes['count']}>
              {selectedIds.length}/{limited}
            </h4>
          </div>
        )}
        <div className={classes['fade-wrap__color-content']}>
          <ul className={classNames(answersListClassName, classes['answers-list'], classes[dataTypeClassName])}>
            {_map(answers, (v, k) => (
              <AnswerList
                key={`answer_${k}`}
                onClick={toggleItem}
                selected={selectedIds.includes(k)}
                // @ts-ignore
                title={v.title || v}
                // @ts-ignore
                subtitle={v.subtitle || ''}
                id={k}
                // @ts-ignore
                icon={icons?.[k]}
                classNameSquare={classNameSquare}
                idDisabled={selectedIds.length === limited && !selectedIds.includes(k)}
                theme={theme}
                withCheckmark={withCheckmark}
                // @ts-ignore
                image={images?.[k]}
              />
            ))}
          </ul>
        </div>
      </div>
      {selectedIds.length > 0 && (
        <div className={classes.button_wrap}>
          <Button
            iconRight={iconRight}
            onClick={handleBtnClick}
            type="submit"
            title={t('_continue')}
          />
        </div>
      )}
    </div>
  );
};

export default MultiplyAnswers;
