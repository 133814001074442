import _merge from 'lodash/merge';

import Localization from '@web-solutions/module-localization';

import { resultResources as resourcesPR } from '@web-solutions/palm-reading';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  en: {
    translation: require('./en.json'),
  },
};

const resultResources = _merge({}, resources, resourcesPR);

export function initLocalization() {
  Localization.init(resultResources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
}
