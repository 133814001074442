import COMPLICATED_1X from './complicated.webp'
import COMPLICATED_2X from './complicated@2x.webp'
import COMPLICATED_3X from './complicated@3x.webp'

import SHY_LOVE_1X from './shy_love.webp'
import SHY_LOVE_2X from './shy_love@2x.webp'
import SHY_LOVE_3X from './shy_love@3x.webp'

export const EMOJIS_IMAGES = {
  COMPLICATED: {
    src: COMPLICATED_1X,
    srcSet: `${COMPLICATED_1X}, ${COMPLICATED_2X} 2x, ${COMPLICATED_3X} 3x`
  },
  SHY_LOVE: {
    src: SHY_LOVE_1X,
    srcSet: `${SHY_LOVE_1X}, ${SHY_LOVE_2X} 2x, ${SHY_LOVE_3X} 3x`
  },
}