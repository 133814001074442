import React from 'react';
import classNames from 'classnames';

import { ImageSource } from 'core/interfaces/images';
import { Theme } from 'core/constants/theme';
import { srcSetToImageSet } from 'core/utils/srcset-to-imageset';

import { QuizStepView } from '../../types';

import classes from './index.module.scss';

interface Props {
  onClick: (id: string) => void;
  title: string;
  icon?: React.FC<{ className?: string }>;
  id: string;
  selected: boolean;
  subtitle: string;
  theme: Theme;
  color: string;
  image: ImageSource;
  className?: string;
  classNameSquare?: string;
  columnsNumber: 2 | 3;
  disabled: boolean;
  classNameTitle: string;
  duoButtons?: boolean;
  view?: QuizStepView;
}

const SquareAnswer: React.FC<Props> = ({
  onClick,
  selected,
  title,
  subtitle,
  icon: IconElement,
  classNameSquare = '',
  className = '',
  classNameTitle = '',
  id,
  disabled = false,
  columnsNumber = 2,
  theme,
  image = null,
  color,
  duoButtons,
  view
}) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };
  const isCaptionView = view === QuizStepView.CAPTION;
  const isBgView = view === QuizStepView.BG;

  const classSquare = classNames([classes.square], classNameSquare, {
    [classes.active]: selected,
    [classes.dark]: theme === Theme.dark,
    [classes.three_column]: columnsNumber === 3,
    [classes.duo]: duoButtons,
    [classes.caption_view]: isCaptionView,
    [classes.bg_view]: isBgView,
    [classes.bg_view_three_column]: columnsNumber === 3 && isBgView,
  })

  const imageSrc = image?.srcSet ? `image-set(${srcSetToImageSet(image?.srcSet)})` : `url(${image?.src})`

  const style = {
    backgroundImage: image ? imageSrc : '',
    backgroundColor: color ? color : '',
  }

  const h4 =
    <h4
      id={`${title}_text`}
      className={classNames([
        classes.title,
        classNameTitle,
      ])}
    >
      {title}
    </h4>

  return (
    <li className={classSquare} style={view ? style : {}}>
      <button
        disabled={disabled}
        type="button"
        id={`${title}_container`}
        onClick={handleClick}
        className={`${classes.button} ${className}`}
      >
        {IconElement && <IconElement className={classes.icon} />}
        {image && !view && <img src={image.src} srcSet={image.srcSet} alt="answer" />}
        {color && !view && <div className={classes.color} style={{ backgroundColor: color }} />}
        {isCaptionView ?
          <div className={classes.title_block}>
            {h4}
          </div>
          :
          h4
        }
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        {isCaptionView && <div className={classes.border} />}
      </button>
    </li>
  );
};

export default SquareAnswer;
