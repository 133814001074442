import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { setPersonalizedData } from 'src/store/profile/actions';

import { InputWrapper } from 'src/screens/components/input-wrapper';

import { OrderButton } from 'src/components/order/order-button';

import { prepareFormDataToSave } from 'src/utils'

import { useForm } from '../../hooks/use-form';

import type { BirthTimeType, BirthDateType, BirthLocationType } from '../../types';

import { BirthDate } from './components/birth_date';

import { BirthTime } from './components/birth_time';

import Birthplace from './components/birthplace';

import classes from './style.module.scss';

const tKey = "personalization";

interface Props {
  onSubmit: () => void;
}

export const PersonalizedForm: React.FC<Props> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const {
    validation,
    data,
    isFormValid,
    setData,
    handleBlur,
  } = useForm();

  useEffect(() => {
    Analytics.trackEvent('personalized_form', EVENT_ACTION.OPEN);
  }, []);

  const handleBirthDateChange = (newDate: BirthDateType) => {
    setData({ ...data, birth_date: newDate });
  };

  const handleBirthTimeChange = (newDate: BirthTimeType) => {
    setData({ ...data, birth_time: newDate })
  }

  const handlePlaceChange = (newDate: BirthLocationType) => {
    setData({ ...data, location: newDate })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Analytics.trackEvent('personalized_form', EVENT_ACTION.SUCCESS, { data });
    const { birth_date, birth_time, location } = prepareFormDataToSave(data);
    dispatch(setPersonalizedData({ birth_date, birth_time, location }));
    onSubmit();
  }

  return (
    <div className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.field_wrap}>
          <label className={classes.top_label}>{t(`${tKey}.form.date_birth.title`)}</label>
          <InputWrapper name="birth_date" validation={validation} tKey={tKey}>
            <BirthDate
              birthDate={data.birth_date}
              onDateChange={handleBirthDateChange}
              onBlurBirthDate={handleBlur}
            />
          </InputWrapper>
        </div>
        <div className={classes.field_wrap}>
          <label className={classes.top_label}>{t(`${tKey}.form.time_birth.title`)}</label>
          <InputWrapper>
            <BirthTime
              birthTime={data.birth_time}
              onTimeChange={handleBirthTimeChange}
            />
          </InputWrapper>
        </div>

        <div className={classes.field_wrap}>
          <label htmlFor="location" className={classes.top_label}>{t(`${tKey}.form.labels.place`)}</label>
          <InputWrapper name="location" validation={validation}>
            <Birthplace
              onPlaceChange={handlePlaceChange}
              onBlurBirthPlace={handleBlur}
              location={data.location}
              className={classNames(classes.field, { [classes.error_border]: !validation.location })}
            />
          </InputWrapper>
        </div>
        <OrderButton
          type='submit'
          className={classes.button}
          disabled={!isFormValid}
          titleClassName={classes.button_title}
          title={t('personalization.form.button')}
        />
      </form>
    </div>
  )
}