import queryString from 'query-string';

import type { PlaceData } from "@web-solutions/core/containers/place-picker/types";

import type { ShippingInformation, ShippingInformationShort } from "src/store/profile";

export type Validation = {
  email: boolean,
  name: boolean,
  last_name: boolean,
  country: boolean,
  state: boolean,
  city: boolean,
  street: boolean,
  apartment: boolean,
  zip_code: boolean,
  phone: boolean,
}

type CheckData = {
  personal: boolean;
  terms: boolean;
};

type ValueType = string | { value: string; label: string; state?: boolean } | CheckData;

const isValueNotEmpty = (value: ValueType, key: string, isNeedState: boolean): boolean => {
  if (key === 'apartment') {
    return true
  }
  if (key === 'checks') {
    const checks = value as CheckData;
    return checks.personal && checks.terms;
  }
  if (typeof value === 'object' && value !== null) {
    if ('value' in value) {
      return key === 'state' && !isNeedState ? true : (value as { value: string }).value !== '';
    }
    return true;
  }
  return value !== '';
};

const requiredFields = ['email', 'name', 'last_name'];

const isValueNotEmptyForShort = (value: ValueType, key: string): boolean => {
  if (key === 'checks') {
    const checks = value as CheckData;
    return checks.personal && checks.terms;
  }
  if (requiredFields.includes(key)) {
    return value !== '';
  }
  return true;
};

export const checkFormValidity = (
  validation: Validation,
  data: ShippingInformation | ShippingInformationShort,
  isShortForm: boolean,
  isNeedState: boolean
): boolean => {
  const areValidationsPassed = Object.values(validation).every(Boolean);
  const areDataValuesValid = Object.entries(data).every(([key, value]) => !isShortForm ? isValueNotEmpty(value, key, isNeedState) : isValueNotEmptyForShort(value, key));
  return areValidationsPassed && areDataValuesValid;
};

const CITY_FIELDS = ['city', 'town', 'hamlet', 'vilage']

export const getFormattedCity = (address: PlaceData['address']) => CITY_FIELDS.reduce((prev, cur) => address[cur] ? address[cur] : prev, '')

export const getSearchQuery = (locationParams: Record<'city' | 'country' | 'state', string>) => `?${queryString.stringify({ ...locationParams, limit: 15 }, { skipEmptyString: true })}`