import { ImagesMap } from 'core/interfaces/images';

import BIG_BAD_1X from './big_bad.webp'
import BIG_BAD_2X from './big_bad@2x.webp';
import BIG_BAD_3X from './big_bad@3x.webp';

import SMALL_BAD_1X from './small_bad.webp';
import SMALL_BAD_2X from './small_bad@2x.webp';
import SMALL_BAD_3X from './small_bad@3x.webp';

import NORMAL_1X from './normal.webp';
import NORMAL_2X from './normal@2x.webp';
import NORMAL_3X from './normal@3x.webp';

import SMALL_GOOD_1X from './small_good.webp';
import SMALL_GOOD_2X from './small_good@2x.webp';
import SMALL_GOOD_3X from './small_good@3x.webp';

import BIG_GOOD_1X from './big_good.webp';
import BIG_GOOD_2X from './big_good@2x.webp';
import BIG_GOOD_3X from './big_good@3x.webp';

export const IMAGES: ImagesMap = {
  BIG_BAD: {
    src: BIG_BAD_1X,
    srcSet: `${BIG_BAD_1X}, ${BIG_BAD_2X} 2x, ${BIG_BAD_3X} 3x`
  },
  SMALL_BAD: {
    src: SMALL_BAD_1X,
    srcSet: `${SMALL_BAD_1X}, ${SMALL_BAD_2X} 2x, ${SMALL_BAD_3X} 3x`
  },
  NORMAL: {
    src: NORMAL_1X,
    srcSet: `${NORMAL_1X}, ${NORMAL_2X} 2x, ${NORMAL_3X} 3x`
  },
  SMALL_GOOD: {
    src: SMALL_GOOD_1X,
    srcSet: `${SMALL_GOOD_1X}, ${SMALL_GOOD_2X} 2x, ${SMALL_GOOD_3X} 3x`
  },
  BIG_GOOD: {
    src: BIG_GOOD_1X,
    srcSet: `${BIG_GOOD_1X}, ${BIG_GOOD_2X} 2x, ${BIG_GOOD_3X} 3x`
  }
};