import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

//@ts-ignore
import Billing from '@web-solutions/module-billing';
import Analytics from '@web-solutions/module-analytics';

import { getApproximateLocation } from '@web-solutions/core/api/nominatim';

import { setApiAuth } from '@web-solutions/core/utils/network';

import { auth as authRequest } from 'src/api/auth';

import { profileSlice } from ".";

export const {
  setEmail,
  setBirthDate,
  setBirthTime,
  setBirthPlace,
  setApproximateLocation,
  setPersonalizedData,
  setShippingInformation,
  setShippingMethod,
  clearPersonalizedData,
} = profileSlice.actions;

export const init = createAsyncThunk(
  `${profileSlice.name}/init`,
  async (_, { dispatch }) => {
    getApproximateLocation()
      .then(r => {
        dispatch(setApproximateLocation(r));
      })
      .catch((err) => {
        console.warn(err);
      })
  }
)

export const auth = createAsyncThunk(
  `${profileSlice.name}/auth`,
  async () => authRequest()
    .then((res: any) => {
      const h = `Bearer ${res?.profile?.access_token}`;

      Billing.setApiAuth(h);
      setApiAuth(h);
    }))

export const processEmail = (email: string): ThunkAction<void, any, unknown, any> =>
  (dispatch, getState) => {
    if (email !== getState().profile.email) {
      Analytics.setUserProperty('email', email);
      Analytics.trackEvent('user', 'info', { email, });

      dispatch(setEmail(email));
    }
  }

export const processBirthDate = createAsyncThunk(
  `${profileSlice.name}/processBirthDate`,
  async (date: string, { dispatch }) => {
    dispatch(setBirthDate(date));
  }
);
