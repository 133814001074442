import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { getShippingInformation } from 'src/store/profile/selectors';

import { ProductType } from 'src/constants/products';

import { selectActiveProduct } from "src/store/cart/selectors";

import {
  getConvertedAddressData,
  getFormattedPhoneNumber
} from 'src/utils'

import type { InfoListItem } from 'src/types/order'

export const useGetShippingDetailsContent = ({ withEmail }: { withEmail?: boolean } = { withEmail: false }): InfoListItem => {
  const {
    name,
    last_name,
    city,
    state,
    street,
    apartment,
    zip_code,
    country,
    phone,
    email
  } = useSelector(getShippingInformation);
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const { fullAddress } = getConvertedAddressData({ street, apartment })

  return isHardCoverBook ?
    {
      title: t('order.info_section_titles.shipping_details'),
      content: [
        { text: `${name}, ${last_name}` },
        { text: fullAddress },
        { text: `${city}, ${state.label ? `${state.label}, ` : ''}${zip_code}` },
        { text: country.label },
        { text: getFormattedPhoneNumber(phone) },
        ...(withEmail ? [{ text: email }] : []),
      ]
    }
    :
    {
      title: t('order.info_section_titles.shipping_details'),
      content: [
        { text: email },
      ]
    }
}