import type { FC, ReactNode } from 'react';
import { useLocation, Routes } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './styles.scss';

interface QuizTransitionProps {
  children: ReactNode,
}

export const QuizTransition: FC<QuizTransitionProps> = ({ children }) => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname.split('/')[1]}
        timeout={600}
        classNames="page-fade"
        unmountOnExit
      >
        <Routes location={location}>
          {children}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}
