import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';
import { Title } from '@web-solutions/core/ui-elements';
import Analytics from '@web-solutions/module-analytics';
import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { NavHeaderBase } from 'src/components/order/nav-header-base';
import { ROUTES } from 'src/constants/routes';

import classes from './style.module.scss';

const tKey = 'order_checkout';
interface Props {
  isHide: boolean;
  price: string;
  onClick: () => void;
}

export const OrderCheckoutHeader: React.FC<Props> = ({ onClick, isHide, price }) => {
  const navigateTo = useNavigation();

  const handleBackClick = () => {
    Analytics.trackEvent('order_checkout', 'back');
    navigateTo(ROUTES.MAIN);
  }

  return (
    <NavHeaderBase onBackClick={handleBackClick} withBorder={!isHide} className={classes.order_checkout}>
      <div className={classNames(classes.header, { [classes.hide]: isHide })}>
        <div className={classes.block}>
          <Title level='h4'>
            {t(`${tKey}.title`)}
          </Title>
          <button onClick={onClick}>
            {t(`${tKey}.${isHide ? 'hide' : 'show'}`)}
          </button>
        </div>
        <p className={classes.price}>{price}</p>
      </div>
    </NavHeaderBase>
  )
}