import { QuizStepKind } from '../types';

import RectangleAnswer from '../components/rectangle-answer';
import MiniRectangleAnswer from '../components/mini-rectangle-answer';
import SquareAnswer from '../components/square-answer';
import Bipolar from '../components/bipolar-answer';
import Number from '../components/number-answer';
import Rate from '../components/rate-answer';
import Oval from '../components/oval-answer';


export const DATA_COMPONENTS = {
  [QuizStepKind.Rectangle]: RectangleAnswer,
  [QuizStepKind.Mini]: MiniRectangleAnswer,
  [QuizStepKind.Square]: SquareAnswer,
  [QuizStepKind.Bipolar]: Bipolar,
  [QuizStepKind.Numeric]: Number,
  [QuizStepKind.Rate]: Rate,
  [QuizStepKind.Oval]: Oval,
};
