import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { getShippingInformation } from 'src/store/profile/selectors';

import { selectActiveProduct } from "src/store/cart/selectors";

import { ProductType } from 'src/constants/products';

import { useGetShippingDetailsContent } from "src/hooks/order"

import type { InfoListItem } from 'src/types/order'

export const useGetShippingInfoContent = (): InfoListItem[] => {
  const { email, name, last_name } = useSelector(getShippingInformation)
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const shippingDetailsContent = useGetShippingDetailsContent();

  return [
    {
      title: isHardCoverBook ? t('order.info_section_titles.contact_information') : t('shipping_information.book_recipient'),
      content: isHardCoverBook ? [{ text: email }] : [{ text: `${name} ${last_name}` }]
    },
    shippingDetailsContent
  ]
}