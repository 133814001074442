import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { getPersonalizedData } from 'src/store/profile/selectors';

const tKey = 'customer'

export const useGetProcessedInfo = () => {
  const personalData = useSelector(getPersonalizedData);

  const { birth_date, birth_time, location } = personalData || {}
  const formattedDate = `${birth_date?.year}-${birth_date?.month.padStart(2, '0')}-${birth_date?.day.padStart(2, '0')}`;

  return {
    birthDateFullInfo: t(`${tKey}.born_on`, {
      day: dayjs(formattedDate).format('dddd, MMMM D, YYYY'),
      time: birth_time?.hour ? t(`${tKey}.born_at`, { time: `${birth_time.hour.padStart(2, '0')}:${birth_time.minutes.padStart(2, '0')}` }) : ''
    }),
    location
  }
}