import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';

import { parseCurrencyString } from '@web-solutions/core/utils/parse-currency';

import { getCurrencySymbols } from '@web-solutions/react-billing/utils/prices';

import { EVENT_ACTION } from 'core/constants/general';

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { getShippingMethod } from 'src/store/profile/selectors';

import { selectActiveProduct } from "src/store/cart/selectors";

import { ProductType } from 'src/constants/products';

import { ProductCard } from 'src/components/product-card';

import { OrderCheckoutHeader } from '../order_checkout_header';

import classes from './style.module.scss';

const tKey = 'order_checkout';

export const OrderCheckout: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false);
  const { product } = useRemoteConfig();
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;
  const { activePrice, currency } = product;
  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const shippingPrice = parseCurrencyString(useSelector(getShippingMethod).price);
  const totalPrice = (parseCurrencyString(activePrice) + (isHardCoverBook ? shippingPrice : 0)).toFixed(2);

  const totalPriceText = `${currencySymbol}${totalPrice}${currencySuffix}`;

  const handleClick = () => {
    Analytics.trackEvent('order_checkout', !showDetails ? EVENT_ACTION.OPEN : EVENT_ACTION.CLOSE);
    setShowDetails(!showDetails);
  }

  return (
    <>
      <OrderCheckoutHeader onClick={handleClick} isHide={showDetails} price={totalPriceText} />
      <div className={classNames(classes.details, { [classes.show]: showDetails })}>
        <ProductCard product={product} className={classes.card} />
        <div className={classes.prices}>
          {
            isHardCoverBook &&
            <>
              <div className={classes.row}>
                <p className={classes.row_title}>{t(`${tKey}.subtotal`)}</p>
                <p className={classes.row_text}>{activePrice}</p>
              </div>
              <div className={classes.row}>
                <p className={classes.row_title}>{t(`${tKey}.shipping`)}</p>
                <p className={classes.row_text}>{!shippingPrice ? t(`${tKey}.calculated`) : `${currencySymbol}${shippingPrice}${currencySuffix}`}</p>
              </div>
            </>
          }
          <div className={classes.row}>
            <p className={classes.row_title}>{t(`${tKey}.total`)}</p>
            <p className={classes.row_text}>{totalPriceText}</p>
          </div>
        </div>
      </div>
    </>
  )
}