import { FC } from 'react';

import { t } from '@web-solutions/module-localization';

import MultiplyAnswersCore from '@web-solutions/core/containers/questions/multiply-answers';
import SingleAnswersCore from '@web-solutions/core/containers/questions/single-answers';

import type { QuizStep as QuizStepProps, Data } from '@web-solutions/core/interfaces/quiz'

import type { QuizNameKeys } from 'src/constants/routes';

import { useQuizNavigation } from 'src/hooks/use-quiz-navigation';

import { STEPS } from '../questions/data/questions'

const tKey = 'quiz.questions';

export const QuizStep: FC<QuizStepProps> = ({ currentPage }) => {
  const { nextScreen } = useQuizNavigation();

  const data = STEPS[currentPage as QuizNameKeys];

  if (!data) return null;

  const question = t(`${tKey}.${currentPage}`, { returnObjects: true, }) as unknown as Data;

  const handleClick = (_selectedIds: string | string[]) => {
    nextScreen();
  };

  switch (data.type) {
    case 'single':
      return (
        <SingleAnswersCore
          analyticsCategory={currentPage}
          data={question}
          onClick={handleClick}
          {...data}
        />
      );
    case 'multi':
      return (
        <MultiplyAnswersCore
          iconRight={false}
          analyticsCategory={currentPage}
          data={question}
          onClick={handleClick}
          {...data}
        />
      );
  }
};
