import { t } from '@web-solutions/module-localization';

import { ProductType } from 'src/constants/products';

import { ReactComponent as CheckIcon } from './icons/check.svg';

import classes from './style.module.scss';

interface ProductProps {
  type: ProductType;
}

const tKey = 'products_info';

export const ProductDescription: React.FC<ProductProps> = ({ type }) => {
  const options = t(`${tKey}.${type}.options`, { returnObjects: true }) as unknown as string[];

  return (
    options?.map((option) => {
      return <div key={option} className={classes.info_option}>
        <CheckIcon />
        {option}
      </div>
    })
  )
}