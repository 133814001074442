export const srcSetToImageSet = (str: string) => {
  const arr = str?.split(',');

  const imageSetArr = arr?.map((item, index) => {
    if (index > 0) {
      return `"${item.trim().substring(0, item.length - 3)}" ${index + 1}x`
    }

    return `"${item.trim()}" ${index + 1}x`
  })


  return imageSetArr?.join(', ')
}