import React, { useMemo } from 'react';
import classNames from 'classnames';

import { Text } from 'core/ui-elements';

import { ImageSource } from 'core/interfaces/images';

import { Theme } from 'core/constants/theme';

import { ReactComponent as IconCheck } from './icons/check.svg';

import classes from './style.module.scss';

interface Props {
  onClick: (id: string) => void;
  title: string;
  icon?: React.ElementType | string;
  id: string;
  selected: boolean;
  subtitle: string;
  theme: Theme;
  color?: string;
  imgClassName?: string;
  image?: ImageSource;
  withCheckmark?: boolean;
}

const RectangleAnswer: React.FC<Props> = ({
  onClick,
  title,
  icon = null,
  id,
  selected,
  subtitle,
  image = null,
  theme,
  color,
  imgClassName = '',
  withCheckmark,
}) => {
  const Icon = useMemo(() => {
    if (typeof icon === 'string') {
      return icon;
    }
    if (!icon) {
      return null;
    }
    const IconElement = icon;
    return <IconElement className={classes.icon} />;
  }, [icon]);

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };

  const classRectangle = classNames(
    classes.rectangle,
    { [classes.dark]: theme === Theme.dark },
    { [classes.active]: selected },
    { [classes.withCheckmark]: withCheckmark },
  );

  return (
    <li className={classRectangle}>
      <button
        onClick={handleClick}
        id={`${title}_container`}
        className={classes.button}
        type="button"
      >
        {Icon}
        {image && <img className={imgClassName} src={image.src} srcSet={image.srcSet} alt="answer" />}
        {color && <div className={classes.color} style={{ backgroundColor: color }} />}
        <div className={classes.description}>
          <h4 id={`${title}_title`} className={classes.title}>
            {title}
          </h4>
          {subtitle && <Text className={classes.subtitle}>{subtitle}</Text>}
          <IconCheck className={classes.iconCheck} />
          {withCheckmark && <div className={classes.checkmark}>
            <IconCheck />
          </div>}
        </div>
      </button>
    </li>
  );
};

export default RectangleAnswer;
