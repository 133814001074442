import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { useGetProcessedInfo } from 'src/hooks/customer';

import classes from './style.module.scss'

export const CustomerInfo: FC<ComponentProps<'div'> > = ({ className, ...props }) => {
  const { birthDateFullInfo, location } = useGetProcessedInfo();

  return (
    <div className={classNames(classes.customerInfoWrapper, className)} {...props}>
      <p className={classes.text}>
        {birthDateFullInfo}
      </p>
      <p className={classes.text}>
        {location?.label}
      </p>
    </div>
  )
}
