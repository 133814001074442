import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { t } from '@web-solutions/module-localization'

import { getShippingMethod, getShippingInformation } from 'src/store/profile/selectors';

import { ProductType } from 'src/constants/products';

import { selectActiveProduct } from "src/store/cart/selectors";

import { useGetShippingDetailsContent } from 'src/hooks/order'

import type { InfoListItem } from 'src/types/order'

import { baseTKey } from '../../../constants'

const tKey = `${baseTKey}.main.details.section_titles`

export const useGetOrderDetailsData = (): InfoListItem[] => {
  const shippingMethod = useSelector(getShippingMethod);
  const { name, last_name } = useSelector(getShippingInformation)
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const orderDate = dayjs().format('MMMM D, YYYY');

  const shippingDetailsContent = useGetShippingDetailsContent({ withEmail: true });

  return isHardCoverBook ?
    [
      { title: t(`${tKey}.date`), content: [{ text: orderDate }] },
      shippingDetailsContent,
      { title: t(`${tKey}.shipping_method`), content: [{ text: shippingMethod.deliverier }, { text: shippingMethod.delivery_interval }] },
    ]
    :
    [
      { title: t(`${tKey}.date`), content: [{ text: orderDate }] },
      { title: t(`shipping_information.book_recipient`), content: [{ text: `${name} ${last_name}` }] },
      shippingDetailsContent,
    ]
}