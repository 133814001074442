import { QuizStepKind } from '@web-solutions/core/containers/questions/types';
import type { QuizStep } from '@web-solutions/base-app/screens/quiz/interfaces';

import { QUIZ_NAMES, type QuizNameKeys } from 'src/constants/routes';

import { IMAGES } from '../images';

type Steps = Partial<Record<QuizNameKeys, QuizStep>>

export const STEPS: Steps = {
  [QUIZ_NAMES.QUIZ_RELATIONSHIP_STATUS]: {
    type: 'single',
    images: {
      "soulmate": IMAGES.HURTS,
      "broke_up": IMAGES.BROKEN_HURT,
      "engaged": IMAGES.SHY_LOVE,
      "married": IMAGES.RING,
      "single": IMAGES.CIRCLE_STAR,
      "difficult": IMAGES.COMPLICATED,
    },
    kind: QuizStepKind.Rectangle,
  },
  [QUIZ_NAMES.QUIZ_FUTURE_GOALS]: {
    type: 'multi',
    icons: {
      'family': '❤️️',
      'career': '💼',
      'health': '💊',
      'married': '💍',
      'traveling': '🌎️',
      'education': '🎓',
      'friends': '👥',
    },
    kind: QuizStepKind.Mini,
    limited: 3,
  },
  [QUIZ_NAMES.QUIZ_COLORS]: {
    type: 'single',
    colors: {
      "red": "#F27067",
      "yellow": "#F2D367",
      "blue": "#0095FF",
      "orange": "#F2AA67",
      "green": "#00D7BC",
      "violet": "#AA82FF"
    },
    kind: QuizStepKind.Rectangle,
  },
  [QUIZ_NAMES.QUIZ_ELEMENT]: {
    type: 'single',
    images: {
      "earth": IMAGES.EARTH,
      "water": IMAGES.WATER,
      "fire": IMAGES.FIRE,
      "air": IMAGES.AIR
    },
    kind: QuizStepKind.Rectangle,
  },
};

