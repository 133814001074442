import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Footer } from 'src/components/order/footer';

import { useGetInitBilling } from 'src/billing/hooks';

import { ProductType } from 'src/constants/products';

import { selectActiveProduct } from "src/store/cart/selectors";

import { OrderCheckout } from './components/order_checkout';
import { ShippingInformation } from './components/shipping_information';
import { SuccessShippingInformation } from './components/success-shipping-information';
import { ShippingMethodInitial } from './components/shipping_method_initial';
import { ShippingMethod } from './components/shipping_method';
import { SuccessShippingMethod } from './components/success-shipping-method';
import { PaymentMethodInitial } from './components/payment_method_initial';
import { PaymentMethod } from './components/payment_method';

import classes from './style.module.scss'
import './style.scss'

enum StepsStatus {
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED'
}

const initialStatuses = {
  shippingInformation: StepsStatus.ACTIVE,
  shippingMethod: StepsStatus.INITIAL,
  paymentMethod: StepsStatus.INITIAL,
}

export const Shipping = () => {
  const [statuses, setStatuses] = useState(initialStatuses);
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  const initBilling = useGetInitBilling();

  useEffect(() => {
    initBilling();
  }, [initBilling])

  useEffect(() => {
    Analytics.trackEvent('shipping_flow', EVENT_ACTION.OPEN);
  }, []);

  const renderShippingInformation = () => {
    switch (statuses.shippingInformation) {
      case StepsStatus.ACTIVE:
        return <ShippingInformation onChangeStatus={
          () => setStatuses({
            ...statuses,
            shippingInformation: StepsStatus.COMPLETED,
            shippingMethod: StepsStatus.ACTIVE,
            paymentMethod: !isHardCoverBook ? StepsStatus.ACTIVE : StepsStatus.INITIAL,
          })}
        />;
      case StepsStatus.COMPLETED:
        return <SuccessShippingInformation
          onEditClick={
            () => setStatuses({
              ...statuses,
              shippingInformation: StepsStatus.ACTIVE
            })} />;
      default:
        return null;
    }
  };

  const renderShippingMethod = () => {
    switch (statuses.shippingMethod) {
      case StepsStatus.INITIAL:
        return <ShippingMethodInitial />;
      case StepsStatus.ACTIVE:
        return <ShippingMethod onChangeStatus={
          () => setStatuses({
            ...statuses,
            shippingMethod: StepsStatus.COMPLETED,
            paymentMethod: StepsStatus.ACTIVE
          })} />;
      case StepsStatus.COMPLETED:
        return <SuccessShippingMethod
          onEditClick={
            () => setStatuses({
              ...statuses,
              shippingMethod: StepsStatus.ACTIVE
            })} />;
      default:
        return null;
    }
  };

  const renderPaymentMethod = () => {
    switch (statuses.paymentMethod) {
      case StepsStatus.INITIAL:
        return <PaymentMethodInitial />;
      case StepsStatus.ACTIVE:
        return <PaymentMethod />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.shippingWrapper}>
      <OrderCheckout />
      <div className={classes.layout}>
        {renderShippingInformation()}
        <div className={classes.devider} />
        {isHardCoverBook &&
          <>
            {renderShippingMethod()}
            < div className={classes.devider} />
          </>
        }
        {renderPaymentMethod()}
        <div className={classes.devider} />
        <Footer />
      </div>
    </div>
  )
}