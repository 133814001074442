import type { FC, ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization'

import { Image, Title } from '@web-solutions/core/ui-elements'

import { ProductType } from 'src/constants/products';

import { selectActiveProduct } from "src/store/cart/selectors";

import { FreeProducts } from '../free-products';

import { CustomerInfo } from '../customer-info';

import { ProductDescription } from '../product-description';

import { IMAGES } from './images'

import classes from './style.module.scss'

const tKey = 'products.books.birth_chart'

interface Props {
  withoutFreeProducts?: boolean;
}

export const ProductAndCustomerInfo: FC<Props & ComponentProps<'div'>> = ({ withoutFreeProducts, className, children, ...props }) => {
  const activeProduct = useSelector(selectActiveProduct)
  const productId = activeProduct?.id;
  
  const isHardCoverBook = productId === ProductType.BIRTH_CHART_HARDCOVER;

  return (
    <div className={classNames(classes.productAndCustomerWrapper, className)} {...props}>
      <div className={classes.headerWrapper}>
        <div className={classes.productWrapper}>
          <Image
            className={classes.image}
            img={isHardCoverBook ? IMAGES.BIRTH_CHART_HARDCOVER : IMAGES.BIRTH_CHART_EBOOK}
            alt='birth chart book'
          />
          <div className={classes.book_name_wrap}>
            <Title level='h4' className={classes.title}>
              {t(`${tKey}.name.${productId}`)}
            </Title>
            <ProductDescription type={productId!} />
          </div>
        </div>
        {children}
      </div>
      {!withoutFreeProducts && <FreeProducts type='card' productType={productId} />}
      <CustomerInfo />
    </div>
  )
}
