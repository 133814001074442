import { useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { REDIRECT_TO_BROWSER, doRedirectToBrowser } from 'core/utils/redirect-to-browser';
import { reInit as reInitRemoteConfig } from 'core/store/remote-config/actions';

import { getRouteAfterFlow, setCompleted, setStartRoute, setStep } from 'src/store/routing/actions';
import { selectFlow } from 'src/store/routing/selectors';
import { ROUTES } from 'src/constants/routes';
import { preloadPage } from 'src/utils/route';

import { useRemoteConfig } from './use-remote-config'

export const useQuizNavigation = () => {
  const navigateTo = useNavigation();
  const dispatch = useDispatch();

  const store = useStore()

  const { redirectToBrowser } = useRemoteConfig();

  const getRoute = useCallback((step: number, mode: 'next' | 'prev'): string => {
    let nextStep = 0;

    if (mode === 'prev') {
      nextStep = step > 0 ? step - 1 : 0;
    } else {
      nextStep = step + 1
    }
    dispatch(setStep(nextStep));
    const flow = selectFlow(store.getState())

    const nextFlowStep = flow[nextStep] ?? ''

    preloadPage(flow[nextStep + 1]);
    preloadPage(flow[nextStep + 2]);

    return ROUTES[nextFlowStep];
  }, [dispatch, store])

  const nextScreen = useCallback(async () => {
    const state = store.getState();
    const { routing: { isCompleted, currentStep } } = state;
    const flow = selectFlow(store.getState())

    if (currentStep === 0 && redirectToBrowser === REDIRECT_TO_BROWSER.ON_CLICK) {
      doRedirectToBrowser({ mode: redirectToBrowser });
    }

    let step = currentStep + 1;

    if (step === flow.length || isCompleted) {
      if (!isCompleted) {
        dispatch(setCompleted(true));

        await dispatch(reInitRemoteConfig());

        sessionStorage.setItem('completed', 'true');
      }

      //@ts-ignore
      const route = await dispatch(getRouteAfterFlow()) as string

      dispatch(setStartRoute(route));

      navigateTo(route);
    }
    else {
      navigateTo(getRoute(currentStep, 'next'));
    }
  }, [dispatch, getRoute, navigateTo, redirectToBrowser, store])


  const setPrevStep = useCallback(() => {
    const { routing: { currentStep } } = store.getState()
    const step = currentStep > 0 ? currentStep - 1 : 0;
    dispatch(setStep(step));
  }, [store, dispatch]);

  const prevScreen = useCallback(() => {
    const { routing: { currentStep } } = store.getState()

    navigateTo(getRoute(currentStep, 'prev'));
  }, [getRoute, navigateTo, store])


  return {
    nextScreen,
    prevScreen,
    setPrevStep,
  }
}
