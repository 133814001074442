import type { FC } from 'react'
import { useDispatch } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements'
import Analytics from '@web-solutions/module-analytics'

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { NavHeaderBase } from 'src/components/order/nav-header-base'
import { ROUTES } from 'src/constants/routes'
import { deleteAllProducts } from 'src/store/cart/actions';


import { baseTKey } from '../../constants'

export const NavHeader: FC = () => {
  const dispatch = useDispatch();

  const navigateTo = useNavigation();

  const handleBackClick = () => {
    Analytics.trackEvent('order_success', 'back');
    dispatch(deleteAllProducts());

    navigateTo(ROUTES.MAIN, { replace: true });
  }

  return (
    <NavHeaderBase onBackClick={handleBackClick} withBorder>
      <Title level='h4'>
        {t(`${baseTKey}.nav.title`)}
      </Title>
    </NavHeaderBase>
  )
}