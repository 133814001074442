import React from 'react';
import classNames from 'classnames';

import { Theme } from 'core/constants/theme';

import { ReactComponent as LIGHT_YES } from './icons/light-yes.svg';
import { ReactComponent as LIGHT_NO } from './icons/light-no.svg';
import { ReactComponent as DARK_YES } from './icons/dark-yes.svg';
import { ReactComponent as DARK_NO } from './icons/dark-no.svg';

import classes from './index.module.scss';

interface Props {
  onClick: (id: string) => void;
  selected: boolean,
  title: string;
  id: string;
  isPositive: boolean;
  theme: Theme;
}

const BipolarAnswer: React.FC<Props> = ({
  onClick,
  selected,
  title,
  id,
  isPositive,
  theme,
}) => {
  const itemClassName = classNames(classes.bipolar__item,
    { [classes.active]: selected },
    { [classes.dark]: theme === Theme.dark },
  );

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(id);
  };

  return (
    <li
      onClick={handleClick}
      className={itemClassName}
    >
      {theme === Theme.dark ?
        isPositive ? <DARK_YES /> : <DARK_NO />
        :
        isPositive ? <LIGHT_YES /> : <LIGHT_NO />
      }
      <div className={classes.title}>{title}</div>
    </li>
  );
};

export default BipolarAnswer;
