import _map from 'lodash/map';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t, T } from '@web-solutions/module-localization';

import { Text } from 'core/ui-elements';
import { LinkButton } from 'core/ui-elements';

import { ImageSource } from 'core/interfaces/images';

import { EVENT_ACTION, } from 'core/constants/general';
import { Theme, } from 'core/constants/theme';
import { QuizStepKind, QuizStepView } from 'core/containers/questions/types'

import { DATA_COMPONENTS } from '../constants';
import NoteBlock from '../components/note-block';

import { IMAGES } from './images/rates';

import { ReactComponent as IconQuotes } from './images/quotes.svg';

import classes from './style.module.scss';

const tKey = 'core';

type Data = {
  answers: {
    [key: string]: string
  };
  uptitle?: string;
  title: string;
  subtitle?: string;
  skip?: string;
  note_text?: string;
}

export interface SingleAnswersProps {
  analyticsCategory: string;
  icons?: {
    [key: string]: string
  };
  images?: {
    [key: string]: ImageSource;
  };
  headImage?: ImageSource;
  kind?: QuizStepKind;
  view?: QuizStepView;
  onClick: (id: string) => void;
  data: Data;
  theme?: Theme;
  colors?: {
    [key: string]: string
  };
  columnsNumber?: 2 | 3;
  rate?: ImageSource;
  onSkipClick?: () => void;
  subTitleClassName?: string;
  titleClassName?: string;
  fullWidth?: boolean;
  duoButtons?: boolean;
  isHeadImageBeforeTitle?: boolean;
  headImageBg?: ImageSource;
  answerClassName?: string;
  imgClassName?: string;
}

const SingleAnswers: React.FC<SingleAnswersProps> = ({
  analyticsCategory,
  icons,
  images,
  headImage,
  kind = QuizStepKind.Rectangle,
  view,
  onClick,
  data,
  theme = Theme.light,
  colors,
  columnsNumber = 2,
  onSkipClick,
  titleClassName,
  subTitleClassName,
  duoButtons,
  isHeadImageBeforeTitle,
  headImageBg,
  answerClassName,
  imgClassName
}) => {
  const { answers, uptitle, title, subtitle, skip, note_text } = data;
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const AnswerList = DATA_COMPONENTS[kind];
  const dataTypeClassName = `answers-list_${kind}`;
  const isTitleBg = (kind === QuizStepKind.Bipolar && !headImageBg) || kind === QuizStepKind.Numeric;
  const isHeadImageBg = kind === QuizStepKind.Bipolar && headImageBg;
  const titleClassNames = classNames(classes.title, titleClassName, { [classes.with_subtitle]: !!subtitle });
  const listClassName = classNames(classes['answers-list'], classes[dataTypeClassName]);
  const classQuestion = classNames(classes.question, {
    [classes.dark]: theme === Theme.dark,
  })

  const memoizedAnswers = useMemo(() => {
    switch (kind) {
      case QuizStepKind.Bipolar:
        return t(`core.bipolar.answers`, { returnObjects: true }) as unknown as { [key: string]: string };
      case QuizStepKind.Numeric:
        return { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' };
      case QuizStepKind.Rate:
        return {
          1: IMAGES.BIG_BAD,
          2: IMAGES.SMALL_BAD,
          3: IMAGES.NORMAL,
          4: IMAGES.SMALL_GOOD,
          5: IMAGES.BIG_GOOD
        };
      default:
        return answers;
    }
  }, [kind, answers]);

  useEffect(() => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsCategory]);

  const handleAnswerClick = (id: string) => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: id });
    setSelectedId(id);
    onClick(id);
  }

  const handleSkipClick = () => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SKIP);
    onSkipClick && onSkipClick();
  }

  return (
    <div className={classQuestion}>
      {uptitle && <Text className={classes.uptitle}>{uptitle}</Text>}
      {
        (headImage && isHeadImageBeforeTitle) &&
        <img className={classNames(classes.headImage, classes.top)} src={headImage.src} srcSet={headImage.srcSet} alt='headImage' />
      }
      <div className={classNames(classes.title_wrap, { [classes.block]: isHeadImageBg })}>
        {isTitleBg && <IconQuotes className={classes.quotes} />}
        {isHeadImageBg && <img className={classes.headImageBg} src={headImageBg.src} srcSet={headImageBg.srcSet} alt='headImageBg' />}
        <h1 className={titleClassNames}><T k={title} /></h1>
      </div>
      {subtitle && <Text className={classNames(classes.subtitle, subTitleClassName)}>{subtitle}</Text>}
      {
        (headImage && !isHeadImageBeforeTitle) &&
        <img className={classes.headImage} src={headImage.src} srcSet={headImage.srcSet} alt='headImage' />
      }
      <div className={classes['inner-wrap']}>
        <ul className={listClassName}>
          {_map(memoizedAnswers, (v, k) => (
            <AnswerList
              key={`answer_${k}`}
              classNameSquare={answerClassName}
              className={classNames(classes.item, { [classes.tall]: isHeadImageBeforeTitle })}
              onClick={handleAnswerClick}
              selected={selectedId === k}
              // @ts-ignore
              title={v.title || v}
              // @ts-ignore
              subtitle={v.subtitle || ''}
              id={k}
              // @ts-ignore
              icon={icons?.[k]}
              // @ts-ignore
              image={images?.[k]}
              isPositive={k === 'yes'}
              theme={theme}
              color={colors ? colors[k] : ''}
              columnsNumber={columnsNumber}
              // @ts-ignore
              rate={v}
              duoButtons={duoButtons}
              view={view}
              imgClassName={imgClassName}
            />
          )
          )}
          {kind === QuizStepKind.Numeric && (
            <div className={classes.number_wraper}>
              <div>{t(`${tKey}.number.left`)}</div>
              <div>{t(`${tKey}.number.right`)}</div>
            </div>
          )}
          {kind === QuizStepKind.Rate && (
            <div className={classes.rate_wraper}>
              <div className={classes.rate_text}>{t(`${tKey}.rate.left`)}</div>
              <div className={classes.rate_text}>{t(`${tKey}.rate.right`)}</div>
            </div>
          )}
        </ul>
      </div>
      {skip && <LinkButton className={classes.skip} onClick={handleSkipClick}>{skip}</LinkButton>}
      {note_text && <NoteBlock text={note_text} />}
    </div>
  );
};

export default SingleAnswers;
