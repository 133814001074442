import type { FC } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'src/constants/routes';
import { SCREENS } from 'src/constants/screens';

import { ProtectedRoute } from 'src/hocs/protected-route';

export const LandingRouter: FC = () => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route element={<ProtectedRoute />}>
        {Object.values(PROTECTED_ROUTES).map((r, i) => (
          <Route key={i} path={r} element={SCREENS[r]} />
        ))}
      </Route>
      {Object.values(PUBLIC_ROUTES).map((r, i) => (
        <Route key={i} path={r} element={SCREENS[r]} />
      ))}
    </Routes>
  )
}
